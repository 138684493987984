.footer {
  @apply pb-20 bg-black-bg-normal pt-16 text-white sm:pt-8 !important;
  &__wrapper {
    @apply text-xs md:text-sm container--2xl px-2 flex flex-col sm:flex-row justify-between sm:items-center gap-16 sm:gap-4 !important;
  }
  &__links {
    &-wrapper {
      @apply flex flex-col lg:flex-row gap-1 lg:gap-4 xl:gap-24 !important;
      ul {
        @apply text-xs md:text-sm flex flex-col gap-1 md:gap-0 !important;
      }
    }
    &-header {
      @apply uppercase font-display tracking-widest;
    }
    @apply grow flex flex-col gap-2;
    a {
      @apply text-text-red xl:text-white;
    }
  }
  &__logo {
    width: 200px;
  }
}
