.support {
  @apply bg-black-bg-normal text-white;
  &__container {
    @apply container--2xl mx-auto flex flex-row flex-wrap justify-between items-center gap-6;
    padding-top: 47px;
    padding-bottom: 47px;
    transition: padding 0.1s linear;
    .support:not(.is-supporter) & {
      @media screen and (max-width: 420px) {
        padding-top: 62px !important;
        padding-bottom: 62px !important;
      }
    }
    .support.show-prompt & {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
  }
  &__content-wrapper {
    @apply flex flex-col flex-wrap gap-4 md:gap-0;
    p {
      @apply m-0 font-normal text-sm md:text-base;
      &:first-child {
        @apply text-3xl md:text-4xl m-0 uppercase font-serif !important;
      }
    }
  }
  &__cta {
    width: 100%;
    @media screen and (min-width: 450px) {
      width: auto;
    }
  }
  &__button {
    @apply bg-purple-normal text-purple-normal flex flex-row justify-start items-center hover:bg-purple-normal hover:text-white relative w-full !important;
    min-height: 55px;
    span {
      @apply mr-4 relative tracking-normal z-10 !important;
    }
    svg {
      @apply px-[20px] text-[20px] z-20 relative;
      &:last-of-type {
        @apply ml-[22px] px-[22px] text-[23px] text-white ml-auto;
      }
    }
    .bg {
      @apply absolute bg-purple-bg-light top-0 bottom-0 left-0;
      right: 55px;
      transition-property: left, right;
      transition-duration: 0.2s;
      transition-timing-function: linear;
    }
    &:hover {
      @apply bg-purple-normal text-white !important;
      transition-property: color;
      transition-duration: 0.2s;
      .bg {
        right: 100%;
      }
    }
  }
}

.support-prompt {
  @apply bg-purple-bg-normal text-white text-center;
  overflow: hidden;
  height: 0px;
  transition: height 0.1s linear;
  .support.show-prompt & {
    height: 46px;
  }
  .support.show-prompt:not(.is-supporter) & {
    @media screen and (max-width: 420px) {
      height: 76px;
    }
    html.font14pt & {
      @media screen and (max-width: 481px) {
        height: 86px;
      }
    }
    html.font16pt & {
      @media screen and (max-width: 542px) {
        height: 120px;
      }
    }
  }
  &__container {
    @apply container mx-auto h-full flex flex-row flex-nowrap items-center justify-center;
    p {
      @apply p-0 uppercase text-xl font-display font-medium;
    }
  }
}
