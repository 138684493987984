.questionaire__header,
.landing .transition-exit .biglink.transition-to,
.landing .transition-appear .biglink.transition-from {
  height: auto;
  @media screen and (min-width: 400px) {
    height: 420px;
  }
  @screen sm {
    height: 320px;
  }
  html.font16pt & {
    height: auto;
    @media screen and (min-width: 400px) {
      height: 620px;
    }
    @screen sm {
      height: 520px;
    }
  }
  html.font14pt & {
    height: auto;
    @media screen and (min-width: 400px) {
      height: 520px;
    }
    @screen sm {
      height: 420px;
    }
  }
}

#emotional .questionaire__header,
.landing .transition-exit #emotional.biglink.transition-to,
.landing .transition-appear #emotional.biglink.transition-from {
  height: auto;
  @media screen and (min-width: 400px) {
    height: 520px;
  }
  @screen sm {
    height: 420px;
  }
  html.font16pt & {
    height: auto;
    @media screen and (min-width: 400px) {
      height: 720px;
    }
    @screen sm {
      height: 620px;
    }
  }
  html.font14pt & {
    height: auto;
    @media screen and (min-width: 400px) {
      height: 620px;
    }
    @screen sm {
      height: 520px;
    }
  }
}

.questionaire__header-transition-wrapper {
  .transition-from-section & {
    opacity: 0;
  }
  .transition-from-section.transition-enter &,
  .transition-from-section.transition-appear & {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.4s !important;
    transition-timing-function: linear;
  }
  .transition-from-section.transition-appear.transition-appear-active &,
  .transition-from-section.transition-enter.transition-enter-active & {
    opacity: 1;
  }
  .transition-from-section.transition-appear-done &,
  .transition-from-section.transition-enter-done & {
    opacity: 1;
  }
}

.questionaire {
  transition-property: background-color, opacity;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  .transition-exit &,
  .transition-exit-active &,
  .transition-exit-done & {
    opacity: 0;
  }
}

.questionaire__header-content-wrapper {
  h1,
  p {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 300ms;
    transition-timing-function: linear;
    .transition-enter &,
    .transition-enter-done & {
      opacity: 1;
    }
    .transition-exit &,
    .transition-exit-done & {
      opacity: 0;
    }
  }
}

.questionaire__header {
  @apply relative text-center py-14 !important;
  color: var(--text-contrast-color) !important;
  &-transition-wrapper {
    @apply relative;
  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color);
  }
  &-wrapper {
    @apply container max-w-2xl mx-auto flex items-center h-full;
  }
  &-content-wrapper {
    @apply max-w-md mx-auto;
    p {
      @apply text-sm md:text-lg;
      @apply leading-8 !important;
    }
    h1 {
      @apply uppercase mb-5;
      opacity: 0;
    }
  }
  .questionaire__close-button {
    @apply absolute;
    font-size: 40px;
    color: var(--text-contrast-color);
    top: -55px;
    right: 10px;
    z-index: 1;
    @screen sm {
      top: -20px;
      right: 25px;
    }
    &:hover {
      @apply text-black-bg-normal;
    }
  }
}

.observed:not(.facts):not(.link-grid) {
  opacity: 0 !important;
  transform: translateY(200px) !important;
  transition: all 0.5s linear !important;
  &.inView {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}

.observed.link-grid {
  opacity: 0 !important;
  transition: all 0.5s linear !important;
  &.inView {
    opacity: 1 !important;
  }
}

.observed.facts {
  ul > li {
    opacity: 0 !important;
    transform: translateY(100px) !important;
    transition-property: all !important;
    transition-delay: 0s !important;
    transition-duration: 0.4s !important;
    transition-timing-function: linear !important;
    &:nth-child(2) {
      transition-delay: 0.4s !important;
    }
    &:nth-child(3) {
      transition-delay: 0.8s !important;
    }
    &:nth-child(4) {
      transition-delay: 1.2s !important;
    }
    &:nth-child(5) {
      transition-delay: 1.6s !important;
    }
  }
}
.observed.facts.inView {
  ul > li {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}

.questionaire {
  background-color: var(--lightest-color);
}

.questionaire__body {
  @apply relative text-text-normal flex flex-col items-center justify-center pb-36 pt-24 px-8 gap-12;
  > * {
    @apply max-w-md;
  }
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    @apply relative max-w-sm mt-20 md:mt-36 mb-4 text-center;
    &:after {
      content: '';
      @apply block mt-1;
      width: calc(100% + 100px);
      margin-left: -50px;
      border-bottom: 1px solid var(--color);
    }
    &:first-of-type {
      @apply mt-0 mb-0;
      &:after {
        content: none;
      }
    }
  }

  > p:first-of-type {
    margin-top: -20px;
  }
  > p > em {
    @apply block not-italic text-center mx-auto;
    color: var(--color);
    #threats & {
      color: var(--text-color);
    }
  }

  blockquote {
    @apply w-full px-8 md:px-16 py-12 md:py-16 flex-row flex-wrap items-center gap-6 !important;
    @apply text-base md:text-xl !important;
    color: var(--text-contrast-color);
    background-color: var(--color);
    h3 {
      @apply flex flex-row flex-wrap font-semibold tracking-wide items-center gap-6 text-2xl md:text-3xl;
    }
    h3:before {
      content: '\f733';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 40px;
      line-height: 1em;
    }
    p {
      @apply text-base md:text-xl leading-6 md:leading-8;
    }
  }

  .facts {
    @apply max-w-2xl w-full -mt-4 md:mt-0 !important;
    ul {
      @apply flex flex-wrap items-center flex-row justify-evenly gap-12 md:gap-12;
      li {
        em {
          @apply sr-only;
        }
      }
    }
  }

  ol {
    @apply w-full list-none flex flex-col gap-12;
    counter-reset: item;
    > li {
      @apply flex flex-row mb-1 items-center gap-3 md:gap-4 md:text-xl;
      counter-increment: item;
    }
    > li:before {
      @apply text-3xl md:text-6xl text-center font-display font-bold md:font-medium flex items-center justify-center;
      content: counter(item);
      color: var(--text-contrast-color);
      background-color: var(--color);
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      border-radius: 25px;
      @screen md {
        width: 90px;
        height: 90px;
        min-width: 90px;
        min-height: 90px;
        border-radius: 45px;
      }
    }
  }
  .list-item {
    border-bottom: 1px solid var(--color);
    &:last-of-type {
      border-bottom: none;
    }
    &__control {
      @apply px-4 py-12 flex flex-row items-start md:items-center gap-4;
      transition: padding 0.3s linear;
    }
    label {
      @apply text-base sm:text-lg md:text-2xl;
      cursor: pointer;
    }
    input[type='checkbox'] {
      @apply flex items-center appearance-none font-bold justify-center;
      font-family: 'Font Awesome 5 Pro';
      cursor: pointer;
      color: var(--color);
      line-height: 1em;
      background-color: var(--white);
      box-shadow: inset 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
      font-size: 30px;
      min-width: 30px;
      min-height: 30px;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      font-size: 15px;
      @screen md {
        font-size: 25px;
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
      }
      &:checked {
        transition: background-color 0.1s linear, box-shadow 0.1s linear;
        box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        background-color: var(--color);
      }
      &:before {
        @apply opacity-0 mt-1;
        color: var(--text-contrast-color);
        content: ' \f00c';
        transition: opacity 0.1s linear;
      }
    }
    input[type='checkbox']:checked {
      &:before {
        @apply opacity-100;
      }
    }
    .body {
      @apply md:text-xl font-normal flex flex-col gap-6 overflow-hidden pr-4;
      padding-left: calc(30px + theme('spacing.8'));
      @screen sm {
        padding-left: calc(40px + theme('spacing.8'));
      }
      > * {
        opacity: 0;
        transition-property: all;
        transition-duration: 400ms;
        transition-timing-function: linear;
        transition-delay: 0s;
      }
      > ul > li {
        opacity: 0;
        transition-delay: 0s;
        transition-property: all;
        transition-duration: 400ms;
        transition-timing-function: linear;
      }
      ul {
        @apply flex flex-col gap-4;
        li {
          @apply relative;
          padding-left: 25px;
        }
        li:before {
          @apply absolute text-xs md:text-sm font-bold align-middle;
          left: 0px;
          top: 5px;
          color: var(--color);
          font-family: 'Font Awesome 5 Pro';
          content: '\f111';
        }
      }
    }
    &.open {
      @apply text-text-darkest !important;
      background-color: var(--light-color);
      .list-item__control {
        @apply pb-6;
      }
      .body {
        @apply pt-0 pb-12;
        background-color: var(--light-color);
        > * {
          opacity: 1;
          transition-delay: 0.3s;
        }
        > ul > li {
          opacity: 1;
          transition-delay: 0.6s;
          &:nth-child(2) {
            transition-delay: .9s;
          }
          &:nth-child(3) {
            transition-delay: 1.2s;
          }
          &:nth-child(4) {
            transition-delay: 1.5s;
          }
          &:nth-child(5) {
            transition-delay: 1.8s;
          }
          &:nth-child(6) {
            transition-delay: 2.1s;
          }
          &:nth-child(7) {
            transition-delay: 2.4s;
          }
          &:nth-child(8) {
            transition-delay: 2.7s;
          }
          &:nth-child(9) {
            transition-delay: 3s;
          }
        }
      }
    }
    &:hover {
      background-color: var(--light-color);
    }
  }
}

.questionaire__more-info {
  @apply flex flex-row flex-nowrap gap-4 md:items-center mt-16;
  &--copy {
    @apply flex flex-col flex-nowrap gap-4 md:gap-0;
    h3 {
      @apply md:text-3xl;
    }
    p {
      @apply md:text-xl;
    }
    a {
      @apply text-text-red underline;
    }
  }
  &--tel {
    @apply text-black-normal no-underline !important;
  }
  &--icon {
    @apply flex flex-row flex-nowrap gap-0 items-center justify-center;
    background-color: theme('colors.white');
    font-size: 25px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 25px;
    @screen md {
      font-size: 40px;
      width: 90px;
      height: 90px;
      min-width: 90px;
      min-height: 90px;
      border-radius: 45px;
    }
  }
}
