@keyframes slidein {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

svg.slide-in {
  animation-duration: 0.2s;
  animation-name: slidein;
  animation-direction: alternate;
}

.chat {
  @apply bg-white fixed;
  z-index: 100000;
  bottom: 0;
  left: 0;
  right: 0;
  &__easy-code {
    @apply flex flex-row flex-wrap items-center gap-0 lg:gap-x-2 w-full bg-purple-lightest px-8 lg:px-0 lg:py-0 lg:w-auto lg:bg-white lg:mr-2 overflow-hidden;
    transition: height 0.1s linear;
    @screen lg {
      transition: none;
    }
    @apply w-full lg:w-0 h-0;
    .some-checked & {
      @apply h-16 lg:h-auto;
      @apply lg:w-auto;
      span {
        @apply lg:text-2xl;
      }
    }
    span {
      @apply text-lg lg:text-base uppercase font-display font-medium;
    }
    > span,
    > div {
      @apply w-6/12 lg:w-auto;
    }
    > div {
      @apply text-center lg:text-left;
    }
    &--code {
      @apply inline-block bg-zinc-100 px-2 py-1 text-brand text-left;
      box-shadow: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
      min-width: theme('spacing.16');
    }
  }
  &__wrapper {
    @apply flex flex-row flex-wrap lg:gap-4 justify-start lg:flex-row-reverse lg:justify-between items-stretch;
  }
  &__collapsible-wrapper {
    @apply opacity-0 lg:opacity-100 h-0 lg:h-auto -z-10 lg:z-0 flex flex-row lg:flex lg:flex-row flex-wrap lg:justify-end grow basis-0;
    transition: opacity 0.2s linear;
    &.open {
      @apply opacity-100 h-auto z-10;
    }
    .some-checked & {
      @apply lg:py-2 lg:flex-nowrap !important;
    }
  }
  &__buttons-wrapper {
    @apply w-full h-auto flex flex-row flex-wrap lg:w-auto;
    min-height: 45px;
    transition: min-height 0.1s linear;
    @screen lg {
      transition: none;
    }
    .some-checked & {
      min-height: 65px;
    }
    button,
    button > span {
      transition: font-size 0.1s linear, padding 0.1s linear;
      @screen lg {
        transition: none;
      }
      .some-checked & {
        @apply text-lg lg:text-3xl !important;
      }
    }
  }
  &__online-button {
    @apply px-4 flex flex-row gap-1 items-center w-6/12 lg:w-auto justify-center lg:justify-start;
    @apply bg-brand text-white hover:bg-black-normal tracking-[.15em] !important;
    .some-checked & {
      @apply px-6 !important;
    }
    @screen sm {
      @apply gap-4;
    }
    /*
    &:disabled {
      @apply bg-zinc-200 hover:bg-zinc-200 text-black-normal !important;
    }
    */
  }
  &__quick-exit {
    &-button {
      @apply px-4 flex flex-row gap-1 items-center w-auto hidden lg:flex justify-center lg:justify-start;
      @apply bg-white text-text-red hover:bg-black-normal hover:text-white !important;
      .some-checked & {
        @apply px-6 !important;
      }
      @screen sm {
        @apply gap-2;
      }
    }
  }
  &__phone-button {
    @apply px-4 flex flex-row justify-center gap-0 items-center w-6/12 lg:hidden text-brand hover:text-white bg-white hover:bg-black-normal !important;
    svg {
      animation-duration: 0.2s;
      animation-name: slidein;
      animation-direction: alternate;
      transform: translateX(-100%);
      position: relative;
      left: 0.5rem;
      @screen sm {
        transform: translateX(-100%);
        left: 0rem;
      }
    }
  }
  &__phone {
    @apply flex flex-wrap gap-4 uppercase p-8;
    @apply lg:flex lg:opacity-100 lg:items-center lg:w-auto lg:gap-2 lg:p-0 lg:pr-4 lg:tracking-tight lg:font-display lg:font-light !important;
    .some-checked & {
      @apply lg:flex-nowrap lg:pr-2 !important;
    }

    &-header {
      @apply w-full lg:w-auto mr-2 font-medium text-lg lg:text-base font-display;
      .some-checked & {
        @apply lg:text-2xl text-brand !important;
      }
      &:after {
        @apply block w-full;
        content: '';
        max-width: 120px;
        border-bottom: 1px solid theme('colors.purple.normal');
        @screen lg {
          border-bottom: none;
        }
      }
    }
    &--gta {
      @apply lg:hidden !important;
    }
    &--gta,
    &--toll-free {
      .some-checked & {
        @apply lg:flex-nowrap !important;
      }
    }
    &--text {
      @apply flex flex-col lg:inline font-bold lg:font-light lg:text-text-normal;
      .some-checked & {
        @apply lg:text-2xl !important;
      }

      span:first-child {
        @apply hidden lg:inline !important;
      }
      span:last-child {
        @apply lg:hidden normal-case font-normal lg:font-normal text-sm !important;
        .some-checked & {
          @apply text-base;
        }
      }
    }
    ul {
      @apply flex flex-col lg:flex-row lg:flex-wrap grow lg:items-center gap-4 uppercase text-sm lg:text-base lg:text-text-normal lg:gap-2;
      .some-checked & {
        @apply lg:text-2xl !important;
      }
      li {
        @apply flex flex-col font-bold lg:font-light lg:block lg:pr-2;
        a {
          @apply font-normal text-brand lg:text-text-normal lg:font-light !important;
        }
        @screen lg {
          border-right: 0.5px solid theme('colors.brand');
        }
      }
    }
  }
}
