@import './styles/typography.css';
@import './styles/landing.css';
@import './styles/questionaire.css';
@import './styles/support.css';
@import './styles/footer.css';
@import './styles/chat.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
  position: relative;
}

.ReactModal__Overlay {
  backdrop-filter: blur(1px);
}

#header-scope {
  position: relative;
  z-index: 999999;
}

.root {
  position: relative;
  z-index: 1;
}

.ReactCollapse--collapse {
  transition: height 300ms !important;
}

.help {
  transition: all 300ms 300ms !important;
  opacity: 1;
  &[aria-hidden='true'] {
    opacity: 0;
    transition: all 300ms 300ms !important;
  }
}

#root {
  overflow: hidden;
}

#emotional {
  --lightest-color: theme('colors.red.lightest');
  --lighter-color: theme('colors.red.lighter');
  --light-color: theme('colors.red.light');
  --color: theme('colors.red.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.white');
}

#isolation {
  --lightest-color: theme('colors.navy.lightest');
  --lighter-color: theme('colors.navy.lighter');
  --light-color: theme('colors.navy.light');
  --color: theme('colors.navy.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.white');
}

#threats {
  --lightest-color: theme('colors.yellow.lightest');
  --lighter-color: theme('colors.yellow.lighter');
  --light-color: theme('colors.yellow.light');
  --color: theme('colors.yellow.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.text.dark');
}
#physical {
  --lightest-color: theme('colors.green.lightest');
  --lighter-color: theme('colors.green.lighter');
  --light-color: theme('colors.green.light');
  --color: theme('colors.green.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.white');
}
#financial {
  --lightest-color: theme('colors.blue.lightest');
  --lighter-color: theme('colors.blue.lighter');
  --light-color: theme('colors.blue.light');
  --color: theme('colors.blue.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.white');
}
#sexual,
#shame {
  --lightest-color: theme('colors.purple.lightest');
  --lighter-color: theme('colors.purple.lighter');
  --light-color: theme('colors.purple.light');
  --color: theme('colors.purple.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.white');
}
#digital {
  --lightest-color: theme('colors.teal.lightest');
  --lighter-color: theme('colors.teal.lighter');
  --light-color: theme('colors.teal.light');
  --color: theme('colors.teal.normal');
  --text-color: theme('colors.text.normal');
  --text-contrast-color: theme('colors.white');
}

.container {
  @apply relative;
  &--lg {
    @apply container max-w-lg;
  }
  &--xl {
    @apply container max-w-xl;
  }
  &--2xl {
    @apply container max-w-2xl;
  }
}

.quick-exit-modal__content {
  @apply text-text-normal;
  display: block;
  margin: 0 auto;
  max-width: 508px;
  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
  }
}
