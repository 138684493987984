.landing {
  position: relative;
  @screen md {
    .link-grid {
      min-height: calc(100vh - 315px);
    }
    .link-grid.some-checked {
      min-height: calc(100vh - 335px);
    }
  }
}

button.biglink {
  @apply overflow-hidden px-2 py-8 lg:px-8 lg:py-2;
  .link-grid--questionnaire-footer & {
    @apply lg:py-8;
  }
  background-color: var(--light-color);
  color: var(--text-color);
  transition-duration: 0.2s;
  transition-delay: 0s;
  transition-timing-function: linear;
  transition-property: opacity, background-color, color;
  opacity: 1;
  z-index: 1;
  .biglink__content {
    @apply mx-auto max-w-xs flex flex-col justify-center text-center items-center gap-4;
  }
  h2,
  p {
    opacity: 1;
    display: block;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-property: opacity, font-size, margin;
  }
  p {
    display: none;
  }
  @screen md {
    p {
      display: block;
    }
  }
  &:hover {
    background-color: var(--color);
    color: var(--text-contrast-color);
  }
  .transition-appear &,
  .transition-enter &,
  .transition-exit & {
    opacity: 0;
  }
  &.transition-from {
    .transition-appear &,
    .transition-enter & {
      background-color: var(--color);
      color: var(--text-contrast-color);
      opacity: 1;
      h2,
      p {
        opacity: 0;
      }
    }
  }
  &.transition-to {
    .transition-exit & {
      background-color: var(--color);
      color: var(--text-contrast-color);
      opacity: 1;
      h2,
      p {
        opacity: 0;
      }
    }
  }
}

.link-grid {
  position: relative;
  display: grid;
  column-gap: 3px;
  row-gap: 3px;
  grid-auto-flow: column;
  justify-items: stretch;
  align-items: stretch;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-flow: row;
  margin-bottom: 0;
}

.three-item-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-auto-flow: row;
  > * {
    &:nth-child(1) {
      grid-column: span 1;
      grid-row: span 2;
    }
    &:nth-child(2),
    &:nth-child(3) {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  @screen sm {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: minmax(0, 1fr);
    > * {
      @apply py-12;
      grid-column: span 1 !important;
      grid-row: span 1 !important;
    }
  }
}

.landing .three-item-grid {
  &.transition-exit,
  &.transition-appear {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-auto-flow: row dense;
    > * {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  &.transition-exit > .transition-to,
  &.transition-appear > .transition-from {
    z-index: 1000;
    order: -1;
    grid-column: span 2;
    grid-row: span 1;
  }
  @screen sm {
    &.transition-exit,
    &.transition-appear {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-auto-flow: row dense;
      > * {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
    &.transition-exit > .transition-to,
    &.transition-appear > .transition-from {
      z-index: 1000;
      order: -1;
      grid-column: span 2;
      grid-row: span 1;
    }
  }
}

.four-item-grid {
  grid-template-columns: repeat(24, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-auto-flow: row;
  > * {
    grid-column: span 12;
    grid-row: span 1;
  }
  @screen lg {
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    > * {
      &:nth-child(1),
      &:nth-child(4) {
        grid-column: span 13;
        grid-row: span 1;
      }
      &:nth-child(2),
      &:nth-child(3) {
        grid-column: span 11;
        grid-row: span 1;
      }
    }
  }
}

.landing .four-item-grid {
  &.transition-exit,
  &.transition-appear {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-auto-flow: row dense;
    > * {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  &.transition-exit > .transition-to,
  &.transition-appear > .transition-from {
    z-index: 1000;
    order: -1;
    grid-column: span 3;
    grid-row: span 1;
  }
  @screen lg {
    &.transition-exit,
    &.transition-appear {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-auto-flow: row dense;
      > * {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
    &.transition-exit > .transition-to,
    &.transition-appear > .transition-from {
      z-index: 1000;
      order: -1;
      grid-column: span 3;
      grid-row: span 1;
    }
  }
}

.five-item-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
  grid-auto-flow: column row;
  > * {
    grid-column: span 1;
    grid-row: span 1;
    &:nth-child(1) {
      grid-column: span 2;
    }
    &:nth-child(2) {
      grid-row: span 2;
    }
    &:nth-child(5) {
      grid-column: span 2;
    }
  }
  @screen lg {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    > * {
      grid-column: span 2;
      grid-row: span 1;
      &:nth-child(1),
      &:nth-child(2) {
        grid-column: span 3;
        grid-row: span 1;
      }
    }
  }
}

.landing .five-item-grid {
  &.transition-exit,
  &.transition-appear {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
    grid-auto-flow: row dense;
    > * {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  &.transition-exit > .transition-to,
  &.transition-appear > .transition-from {
    z-index: 1000;
    order: -1;
    grid-column: span 2;
    grid-row: span 1;
  }
  @screen lg {
    &.transition-exit,
    &.transition-appear {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(3, minmax(0, 1fr));
      grid-auto-flow: row dense;
      > * {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
    &.transition-exit > .transition-to,
    &.transition-appear > .transition-from {
      z-index: 1000;
      order: -1;
      grid-column: span 2;
      grid-row: span 1;
    }
  }
}

.six-item-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
  grid-auto-flow: row;
  > * {
    h2 {
      @apply text-3xl md:text-3xl;
    }
    &:nth-child(1),
    &:nth-child(2) {
      grid-column: span 1;
      grid-row: span 1;
    }
    &:nth-child(3) {
      grid-column: span 1;
      grid-row: span 2;
    }
    &:nth-child(4),
    &:nth-child(5) {
      grid-column: span 1;
      grid-row: span 1;
    }
    &:nth-child(6) {
      grid-column: span 2;
      grid-row: span 1;
    }
  }
  @screen lg {
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-template-rows: repeat(24, minmax(0, 1fr));
    grid-auto-flow: column;
    > * {
      @apply py-14;
      &:nth-child(1),
      &:nth-child(2) {
        grid-column: span 7;
        grid-row: span 12;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        grid-column: span 12;
        grid-row: span 8;
      }
      &:nth-child(6) {
        grid-column: span 5;
        grid-row: span 24;
      }
    }
  }
}

.seven-item-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
  grid-auto-flow: row;
  > * {
    &:nth-child(1) {
      grid-column: span 2;
      grid-row: span 1;
    }
    &:nth-child(2),
    &:nth-child(3) {
      grid-column: span 1;
      grid-row: span 1;
    }
    &:nth-child(4) {
      grid-column: span 1;
      grid-row: span 2;
    }
    &:nth-child(5),
    &:nth-child(6) {
      grid-column: span 1;
      grid-row: span 1;
    }
    &:nth-child(7) {
      grid-column: span 2;
      grid-row: span 1;
    }
  }
  @screen lg {
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-template-rows: repeat(24, minmax(0, 1fr));
    grid-auto-flow: column dense;
    > * {
      &:nth-child(1) {
        grid-column: span 10;
        grid-row: span 12;
      }
      &:nth-child(2),
      &:nth-child(3) {
        grid-column: span 5;
        grid-row: span 12;
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        grid-column: span 8;
        grid-row: span 8;
      }
      &:nth-child(7) {
        grid-column: span 6;
        grid-row: span 24;
      }
    }
  }
}

.landing .seven-item-grid {
  &.transition-exit,
  &.transition-appear {
    grid-auto-flow: row dense;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    > * {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  &.transition-exit > .transition-to,
  &.transition-appear > .transition-from {
    z-index: 1000;
    order: -1;
    grid-column: span 2;
    grid-row: span 1;
  }
  @screen lg {
    &.transition-exit,
    &.transition-appear {
      grid-auto-flow: row dense;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));
      > * {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
    &.transition-exit > .transition-to,
    &.transition-appear > .transition-from {
      z-index: 1000;
      order: -1;
      grid-column: span 2;
      grid-row: span 1;
    }
  }
}
