@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
  @apply font-normal;
  @apply text-sm md:text-base;
}

@layer components {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-display font-medium uppercase antialiased;
  }
  h1 {
    @apply text-3xl md:text-5xl;
  }
  h2 {
    @apply text-2xl md:text-3xl;
  }
  h3 {
    @apply text-xl md:text-2xl;
  }
  h4 {
    @apply text-lg md:text-xl;
  }
  h5 {
    @apply text-base md:text-lg;
  }
  h6 {
    @apply text-sm md:text-base;
  }
}

p,
span,
blockquote,
ul,
ol {
  @apply antialiased;
  font-size: inherit;
  font-family: inherit;
}

button:not(.button--link),
.button:not(.button--link) {
  @apply font-display font-medium uppercase text-button antialiased;
  span {
    @apply font-display font-medium uppercase text-button antialiased;
  }
  &.red {
    @apply text-base;
    background-color: #c71122;
    color: theme('colors.white');
    padding: 13px;
    min-width: 140px;
    &:hover {
      &.red {
        background-color: theme('colors.text.dark');
        color: theme('colors.white');
      }
    }
  }
}
